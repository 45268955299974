<template>
  <div class="container-fluid reviews">
    <h2 class="text-center mt-5 mb-5 text-uppercase">Avis</h2>
    <VueSlickCarousel :slidesToShow="!isMobile ? 3 : 1" v-bind="settings">
      <div
        v-for="(testimonial, index) in testimonials"
        :key="index"
        class="d-flex justify-content-center"
      >
        <div class="testimonial justify-content-center">
          <div>
            <img :src="require('../assets/img/avatar.png')" alt="" />
            <p>{{ testimonial.name }}</p>
          </div>
          <p>{{ testimonial.text }}</p>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Reviews",
  components: { VueSlickCarousel },
  created() {
    if (window.innerWidth < 769) {
      this.isMobile = true;
    } else this.isMobile = false;
  },
  data() {
    return {
      isMobile: false,
      settings: {
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
      },
      testimonials: [
        {
          name: "Enzo",
          text: "Au début j’étais sceptique mais ça marche vraiment, ça motive et ça fait réfléchir en même temps.",
        },
        {
          name: "Lola",
          text: " J’ai eu la chance d’utiliser l’appli encore en phase de test et c’est grave cool.",
        },
        {
          name: "Yannick",
          text: "Si vous êtes en remise en question y’a rien de mieux en plus ça permet de planter des arbres.",
        },
        {
          name: "Ange",
          text: "Pour ma part les « citation » me correspondent comme par magie, et ça fait plaisir de voir le projet éco réparatrice.",
        },
      ],
    };
  },
};
</script>

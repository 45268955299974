<template>
  <div>
    <div v-if="isMobile" class="get-started">
      <router-link
        class="get-started-btn main-button"
        to="/login"
        :style="isLoggedIn ? 'visibility: hidden' : ''"
      >
        <span>{{ context.buttonText }}</span>
      </router-link>
      <div
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-anchor-placement="bottom-center"
        class="message-box d-flex"
        :id="context.id + '-dialog'"
      >
        <div class="box-right">
          <p>{{ context.dialogText }}</p>
        </div>
        <img class="invisible" src="../assets/img/logo-hello.svg" alt="" />
      </div>
    </div>
    <div v-else class="desktop-get-started container">
      <div
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-anchor-placement="top-center"
        class="message-box"
      >
        <div class="box-right">
          <p>{{ context.dialogText }}</p>
        </div>
        <router-link class="get-started-btn main-button" to="/login">
          <span>{{ context.buttonText }}</span>
        </router-link>
        <img src="../assets/img/logo-hello-desktop.svg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["context"],
  data() {
    return {
      isMobile: false,
      isLoggedIn: false,
    };
  },
  created() {
    if (window.innerWidth < 769) {
      this.isMobile = true;
    } else this.isMobile = false;

    this.isLoggedIn = this.$store.state.accessToken;
  },
  name: "GetStartedSection",
};
</script>

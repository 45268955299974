<template>
  <div class="home">
    <div class="inner-get-started">
      <img
        v-if="isMobile"
        data-aos="fade-left"
        data-aos-once="true"
        :style="`position: fixed; top: ${95 + (platform === 'ios' ? 30 : 0)}px`"
        id="robot"
        src="../assets/img/logo-hello.svg"
        alt=""
      />
      <GetStartedSection :context="dialog1Context" />
      <GetStartedSection :context="dialog2Context" />
    </div>
    <Reviews />
    <Pricing v-if="!isLoggedIn" />
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import GetStartedSection from "../components/GetStartedSeciton.vue";
import Pricing from "../views/Pricing.vue";
import Reviews from "../components/Reviews.vue";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: "Edays - Motivation personnelle - comment trouver la motivation",
      meta: [
        {
          name: "title",
          content:
            "Edays - Motivation personnelle - comment trouver la motivation",
        },
        {
          name: "description",
          content:
            "Edays vous aide à trouver la motivation et passer à l’action",
        },
        {
          property: "og:title",
          content:
            "Edays - Motivation personnelle - comment trouver la motivation",
        },
        { property: "og:site_name", content: "Edays" },
        { property: "og:type", content: "website" },
      ],
    };
  },
  components: {
    GetStartedSection,
    Pricing,
    Reviews,
  },
  data() {
    return {
      isMobile: false,

      dialog1Context: {
        id: "section-1",
        dialogText: `"Révélez votre vrai nature et sauver là" Let’s GO !!!`,
        buttonText: "Commencez",
      },
      dialog2Context: {
        id: "section-2",
        dialogText: `"Edays vous motive et protège votre planète"`,
        buttonText: "Rejoindre",
      },
      isLoggedIn: false,
      platform: "web",
    };
  },
  created() {
    if (window.innerWidth < 769) {
      this.isMobile = true;
      const iosPadding = this.platform === "ios" ? 30 : 0;
      window.addEventListener("scroll", () => {
        const robot = document.getElementById("robot");
        const dialog2 = document.getElementById("section-2-dialog");

        if (!dialog2 || !robot) return;

        if (window.scrollY >= dialog2.offsetTop - (95 + iosPadding)) {
          robot.style.position = "absolute";
          robot.style.top = dialog2.offsetTop + "px";
        } else {
          robot.style.position = "fixed";
          robot.style.top = `${95 + iosPadding}px`;
        }
      });
    } else this.isMobile = false;

    this.isLoggedIn = this.$store.state.accessToken;

    this.platform = Capacitor.getPlatform();
  },
};
</script>
